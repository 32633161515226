import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><em parentName="p">{`Deload Week`}</em></p>
    <p>{`DB Deadlifts 12-12-12`}</p>
    <p>{`then,`}</p>
    <p>{`3 Rounds for time of:`}</p>
    <p>{`10-DB Deadlifts (50/35’s)`}</p>
    <p>{`15-Wall Balls (20/14)`}</p>
    <p>{`10-KB SDHP’s (53/35)`}</p>
    <p>{`15-Burpees`}</p>
    <p>{`*`}{`*`}{`Bonus WOD`}{`*`}{`*`}</p>
    <p>{`1000M Row`}</p>
    <p>{`rest 1:00`}</p>
    <p>{`500M Row`}</p>
    <p>{`for time.`}</p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`All gym members and their families are invited to our annual
Christmas party on Saturday, December 10th at 5:00pm at the Barnett
home.  We will have a chili contest with a trophy up for grabs!  If
you’re last name begins with A-M please bring a side dish, N-Z please
bring a dessert.  Please RSVP to let us know if you will be bringing
chili and how many are attending and we’ll send you the address.  RSVP
to: Daniel\\@crossfitlouisvilleeast.com`}</em></strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      